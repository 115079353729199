export class UtilsService{

  public static getInitials(input: string) {
    // Split the input string into words by spaces
    const words = input.split(' ').filter(word => word.length > 0);

    // Check the number of words and handle accordingly
    if (words.length === 0) {
      return ''; // Return an empty string if no words
    } else if (words.length === 1) {
      return words[0].slice(0, 2).toUpperCase(); // Return the first two letters if only one word
    } else {
      // Get the initials of the first three words
      let initials = '';
      for (let i = 0; i < Math.min(3, words.length); i++) {
        initials += words[i][0].toUpperCase();
      }
      return initials;
    }
  }
}
